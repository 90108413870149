import React from 'react';
import { ThemeProvider } from 'styled-components';
import { saasModernTheme } from '../src/common/theme/saasModern';

import '../src/i18n/config';

const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={saasModernTheme}>
     {element}
    </ThemeProvider>
  );
};

export default wrapRootElement;
