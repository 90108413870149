import React from 'react';
import './styles.css';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import saasModernTheme from '../../theme/saasModern/index';
import colors from '../../theme/saasModern/colors';
import { rem } from 'polished';

// interface CookieBannerProps {
//   onConsent: (value: boolean) => void;
// }
const fontWeights =  { 
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};
const CookieBanner = ({ onConsent } ) => {
  const { t } = useTranslation();

  const DAYS_TO_EXPIRE_COOKIES = 30;

  const handleAccept = () => {
    onConsent(true);
  };

  const handleDecline = () => {
    onConsent(false);
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText={t('home.cookies_banner.accept')}
      enableDeclineButton
      declineButtonText={t('home.cookies_banner.reject')}
      onAccept={handleAccept}
      onDecline={handleDecline}
      buttonStyle={{
        backgroundColor: colors.primary,
        color: colors.white,
        padding: '12px 24px 12px 24px',
        margin: 0,
        marginRight: '22px',
        borderRadius: '8px',
        fontWeight: fontWeights.bold,
      }}
      declineButtonStyle={{
        backgroundColor: colors.white,
        border: `1px solid ${colors.black}`,
        color: colors.primary,
        padding: '12px 24px 12px 24px',
        marginLeft: 0,
        marginRight: '22px',
        borderRadius: '8px',
        fontWeight: fontWeights.bold,
      }}
      style={{
        background: colors.white,
        boxShadow: '0px 3px 16px rgba(0, 0, 0, 0.15)',
        color: colors.primary,
        fontWeight: fontWeights.regular,
        fontSize: rem('16px'),
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      contentStyle={{
        margin: 0,
        color: colors.black,
      }}
      containerClasses="cookieContainerClass"
      expires={DAYS_TO_EXPIRE_COOKIES}
    >
      {t('home.cookies_banner.body')}
    </CookieConsent>
  );
};

export default CookieBanner;
