import wrapPageElement from './config/wrapPageElement';
import wrapRootElement from './config/wrapRootElement';

const supportedLanguages = ['es', 'en' ];
const defaultLanguage = 'es';
const browserLanguage = window.navigator.language.slice(0, 2);

const isBaseUrl =
  window.location.pathname === '/' ||
  window.location.pathname === '/index.html';

if (supportedLanguages.includes(browserLanguage) && isBaseUrl) {
  // window.location.href = `/${browserLanguage}/`
  window.location.href = `/${defaultLanguage}/`;
} else if (isBaseUrl) {
  window.location.href = `/${defaultLanguage}/`;
}

export { wrapPageElement, wrapRootElement };
