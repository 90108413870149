import React from 'react';
import Layout from '../src/containers/Layout'

//https://andremonteiro.pt/gatsby-i18next-wrap-page-element/

const wrapPageElement = ({ element }) => {
  const newElement = React.cloneElement(
    element,
    element.props,
    React.cloneElement(
      element.props.children,
      element.props.children.props,
      React.createElement(
        Layout,
        undefined, 
        element.props.children.props.children,
      ),
    ),
  );

  return newElement;
};

export default wrapPageElement;
