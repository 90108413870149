const colors = {
  transparent: 'transparent', // 0
  black: '#000000', // 1
  white: '#ffffff', // 2
  headingColor: '#302b4e',
  textColor: '#43414e', // 3
  labelColor: '#767676', // 4
  inactiveField: '#f2f2f2', // 5
  inactiveButton: '#b7dbdd', // 6
  inactiveIcon: '#EBEBEB', // 7
  primary: '#FF3A1D', // 8
  primaryHover: '#FF3A1D', // 9
  secondary: '#FF8300', // 10
  secondaryHover: '#FF8300', // 11
  yellow: '#FFD733', // 12
  yellowHover: '#FFD733', // 13
  borderColor: '#1b1e25', //14
  primaryBoxShadow: '0px 8px 20px -6px rgba(255, 58, 29, 1)',
  secondaryBoxShadow: '0px 8px 20px -6px rgba(255, 131, 0, 1)',
  ternaryBoxShadow: ' 0px 3px 8px 0px rgba(43, 83, 135, 0.08)',
};

export default colors;
