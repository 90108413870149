import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import ReactWhatsappButton from 'react-whatsapp-button';
import { Script } from 'gatsby';
import { saasModernTheme } from '../../common/theme/saasModern/index';
import CookieBanner from '../../common/components/CookieBanner';
import { getCookieConsentValue } from 'react-cookie-consent';
import Cookies from 'js-cookie';
import { ThemeProvider } from 'styled-components';
 
import {GlobalStyle} from  '../../containers/SaasModern/sassModern.style'; 
// import 'styles/global.css';

// interface LayoutProps {
  // children: React.ReactNode;
// }

const BrevoWebChat = <Script>
{`
  (function(d, w, c) {
    w.BrevoConversationsID = '64a885a923be191ed932f906';
    w[c] = w[c] || function() {
        (w[c].q = w[c].q || []).push(arguments);
    };
    var s = d.createElement('script');
    s.async = true;
    s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
    if (d.head) d.head.appendChild(s);
  })(document, window, 'BrevoConversations');
`}
</Script>;
 
const Layout = ({ children }) => {
  const location = useLocation();
  const [hasConsentValue, setConsentValue] = useState(false);

  const handleCookieConsent = (value) => {
    setConsentValue(value);
  };

  const isNotFoundPage = () => {
    switch (location.pathname) {
      case '/en/not-found':
      case '/es/not-found':
      case '/en/something-went-wrong':
      case '/es/something-went-wrong':
        return true;
      default:
        return false;
    }
  };

  // const shouldShowFooter = !isNotFoundPage();


  useEffect(() => {
    // const language = window.navigator.language.slice(0, 2);

    // if (supportedLanguages.includes(language)) {
    //   window.localStorage.setItem('language', language);
    // } else {
      window.localStorage.setItem('language', 'es');
    // }
  }, []);

  useEffect(() => {
    const consentValue = getCookieConsentValue();
    setConsentValue(consentValue === 'true');
  }, []);

  // useEffect(() => {
  //   if (!hasConsentValue) {
  //     const googleAnalyticsCookies = Object.keys(Cookies.get()).filter(
  //       (cookies) => cookies.startsWith('_ga'),
  //     );
  //     googleAnalyticsCookies.forEach((cookie) => Cookies.remove(cookie));
  //   }
  // }, [hasConsentValue]);

  
 
  return (
    <>   
    
      {children}

      <ReactWhatsappButton
        animated
        countryCode="1829"
        phoneNumber="6180062"
        style={{
          right:'20px',
          bottom:'12vh',
          backgroundColor: 'black',
          boxShadow: '0 0 0 0 rgba(90, 111, 201, 10)'
        }}
      />

      <CookieBanner onConsent={handleCookieConsent} />
      {hasConsentValue ? BrevoWebChat : null}
      </>
  );
};

export default Layout;